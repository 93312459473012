.Loading_loading__lB734 {
  display: flex;
  flex-grow: 1;
  align-content: center;
}
.Loading_loading__lB734 .Loading_loader__174AU {
  align-self: center;
}

.Chat_chat__2Zknm {
  flex-grow: 2;
  min-height: 0;
  min-width: 0;
  padding-bottom: 1rem;
  margin: 0 auto;
  width: 100%;
  pointer-events: auto;
  overflow-x: hidden;
  overflow-y: scroll;
  white-space: pre-wrap;
  word-break: normal;
}

.Chat_chatItem__2zvY6 {
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
  opacity: 1;
  animation: Chat_fadein__3DQJm 320ms ease-out;
}

@keyframes Chat_fadein__3DQJm {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.Chat_chatRow__254rT {
  display: flex;
  flex-direction: row;
  padding: 0 .5rem;
  max-width: 100%;
}

.Chat_chatRow__254rT > div > div {
  max-width: 100%;
  height: auto;
}

.Chat_chatMessage__1X_q4  {
  max-width: 100%;
  min-height: auto;
  height: auto;
  /* Hack IE11: fix the height the buble message */
  display: table-cell !important;
}

.Chat_agent__2j9ke {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Chat_avatar__2Rwld {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: .5rem;
  margin-bottom: .5rem;
  box-shadow: 0 0 1px #000;
}

.Chat_agentName__2_jgE {
  font-size: 12px;
  padding: .25rem;
  font-weight: bold;
  color: #777;
}

.Offline_offline__3Ux_9 {
  display: flex;
  flex-grow: 1;
  align-content: center;
  justify-content: center;
}
.Offline_offline__3Ux_9 .Offline_inner__36ewH {
  width: 70%;
  align-self: center;
  text-align: center;
}

.App_app__3r08c {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #fff;
}

* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@media screen and (min-width: 360px) {
  html {
    font-size: 15px;
  }
}

@media screen and (min-width: 600px) {
  html {
    font-size: 16px;
  }
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

body {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  overflow-y: scroll;
}

input {
  background: none;
  border: none;
  outline: none;
}

input {
  -webkit-appearance: none;
  font-size: 16px;
}

.scrolls {
  -webkit-overflow-scrolling: touch;
}

.vchat-text-input {
  border-bottom: 1px solid #eee;
}

#chat a {
  color: #16171a;
  text-decoration: underline;
}

#chat .from-me a {
  color: #fff;
  text-decoration: underline;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(113,113,113, 0.2);
  border-right: 1.1em solid rgba(113,113,113, 0.2);
  border-bottom: 1.1em solid rgba(113,113,113, 0.2);
  border-left: 1.1em solid #717171;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

