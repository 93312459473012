* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@media screen and (min-width: 360px) {
  html {
    font-size: 15px;
  }
}

@media screen and (min-width: 600px) {
  html {
    font-size: 16px;
  }
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

body {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  overflow-y: scroll;
}

input {
  background: none;
  border: none;
  outline: none;
}

input {
  -webkit-appearance: none;
  font-size: 16px;
}

.scrolls {
  -webkit-overflow-scrolling: touch;
}

.vchat-text-input {
  border-bottom: 1px solid #eee;
}

#chat a {
  color: #16171a;
  text-decoration: underline;
}

#chat .from-me a {
  color: #fff;
  text-decoration: underline;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(113,113,113, 0.2);
  border-right: 1.1em solid rgba(113,113,113, 0.2);
  border-bottom: 1.1em solid rgba(113,113,113, 0.2);
  border-left: 1.1em solid #717171;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
