:local(.offline) {
  display: flex;
  flex-grow: 1;
  align-content: center;
  justify-content: center;
}
:local(.offline .inner) {
  width: 70%;
  align-self: center;
  text-align: center;
}
