:local(.chat) {
  flex-grow: 2;
  min-height: 0;
  min-width: 0;
  padding-bottom: 1rem;
  margin: 0 auto;
  width: 100%;
  pointer-events: auto;
  overflow-x: hidden;
  overflow-y: scroll;
  white-space: pre-wrap;
  word-break: normal;
}

:local(.chatItem) {
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
  opacity: 1;
  animation: fadein 320ms ease-out;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

:local(.chatRow) {
  display: flex;
  flex-direction: row;
  padding: 0 .5rem;
  max-width: 100%;
}

:local(.chatRow) > div > div {
  max-width: 100%;
  height: auto;
}

:local(.chatMessage)  {
  max-width: 100%;
  min-height: auto;
  height: auto;
  /* Hack IE11: fix the height the buble message */
  display: table-cell !important;
}

:local(.agent) {
  display: flex;
  flex-direction: row;
  align-items: center;
}

:local(.avatar) {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: .5rem;
  margin-bottom: .5rem;
  box-shadow: 0 0 1px #000;
}

:local(.agentName) {
  font-size: 12px;
  padding: .25rem;
  font-weight: bold;
  color: #777;
}
